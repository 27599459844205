import "./bootstrap.config";

import axios from "axios";
import Vue from "vue";
import store from "./store/index";
import i18n from "./i18n/index";

import * as Sentry from "@sentry/vue";

import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import numeral from "numeral";
import ReconnectingWebSocket from "reconnecting-websocket";
import jQuery from "jquery";
import { initDatePicker } from "./library/date-picker";
import "select2";
import "select2/dist/js/i18n/de";
import "select2/dist/js/i18n/en";
import "select2/dist/js/i18n/fr";

let sentry_dsn = document.documentElement.getAttribute("data-sentry-dsn");
let sentry_release = document.documentElement.getAttribute(
    "data-sentry-release"
);
let sentry_environment = document.documentElement.getAttribute(
    "data-sentry-environment"
);
Sentry.init({
    Vue,
    dsn: sentry_dsn,
    release: sentry_release,
    environment: sentry_environment,
});

if (USER_ID !== undefined) {
    Sentry.setUser({
        email: USER_EMAIL,
        id: USER_ID,
    });
}
run();

function run() {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFToken";

    Vue.use(BootstrapVue);

    Vue.filter("duration", function (value) {
        if (value) {
            return numeral(value).format("00:00:00");
        }
        return "00:00:00";
    });

    Vue.directive("autofocus", {
        inserted: function (el) {
            el.focus();
        },
    });

    window.addEventListener("load", function () {
        i18n.locale = LANGUAGE;
        if (document.getElementById("app") !== null) {
            new Vue({
                el: "#app",
                store: store,
                i18n: i18n,
                components: {
                    HotdeskQueues: () =>
                        import("./components/HotdeskQueues.vue"),
                    CallButton: () => import("./components/CallButton.vue"),
                    BarcodeInput: () => import("./components/BarcodeInput.vue"),
                    CallList: () => import("./components/CallList.vue"),
                    CallRouting: () => import("./components/CallRouting.vue"),
                    HotdeskIcon: () => import("./components/HotdeskIcon.vue"),
                    HotdeskList: () => import("./components/HotdeskList.vue"),
                    PhonenumberInput: () =>
                        import("./components/PhonenumberInput.vue"),
                    QueueDevelopment: () =>
                        import("./components/QueueDevelopment.vue"),
                    BarChart: () => import("./components/BarChart.vue"),
                    HotdeskStatus: () =>
                        import("./components/HotdeskStatus.vue"),
                    CopyDataValueToClipboard: () =>
                        import("./components/CopyDataValueToClipboard.vue"),
                },
                mounted() {
                    initLegacy();
                },
                created() {
                    store.dispatch("loadUserHotdesk");
                    store.dispatch("loadHotdesks");
                    setInterval(
                        () => {
                            store.dispatch("loadUserHotdesk");
                            store.dispatch("loadHotdesks");
                        },
                        15 * 60 * 1000
                    );
                },
            });
        }
    });
}

function initLegacy() {
    // Select2
    // --------------------------------
    function initSelect2() {
        jQuery("select")
            .not(".select2-initialized")
            .each(function () {
                var selectElement = jQuery(this);
                selectElement.addClass("select2-initialized");
                selectElement.select2({
                    language: LANGUAGE,
                    width: false,
                    allowClear: false,
                });
            });
    }

    initDatePicker();
    initSelect2();
    jQuery(document).ajaxComplete(function () {
        initSelect2();
        initDatePicker();
    });

    // Asterisk Event WebSockets
    // --------------------------------
    const protocol = window.location.protocol === "http:" ? "ws://" : "wss://";
    const url = protocol + window.location.host + "/ws/asterisk/events/";
    const sock = new ReconnectingWebSocket(url);

    sock.addEventListener("message", (event) => {
        console.debug("receive WebSocket message", { event: event });
    });

    sock.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data.type === "update_hotdesk") {
            store.dispatch("setHotdesk", {
                hotdesk: data.hotdesk,
            });
        }
        if (data.type === "update_queue") {
            store.commit("SET_HOTDESK_QUEUE", {
                queue: data.queue,
            });
        }
    };
}
